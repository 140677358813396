.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.move-up {
  margin-top: -25px;
}

.block {
  display: inline-block;
}

 .descriptor {
  color: #fff;
  font-size: 9px;
}

.navbar {
  background-color: #2C333A;
  color: #fff;
}

.navbar-brand {
  color: #FFF !important;
}

.input-padding {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 250px;
}

.convert-button-padding {
  margin-left: 15px;
  padding: 9px !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mt2 {
  margin-top: 20px;
}

.big {
  font-size: 24px;
}

.tight-container {
  max-width: 700px;
  color: #000000;
  text-align: left;
  display: inline-block;
  padding: 100px 0 0 0;
}

.center {
  text-align: center;
}

.error-message {
  background-color: green;
  padding: 20px;
}
