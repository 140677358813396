
.heading-font {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 1.8em;
}

.heading-font-plus {
  text-align: center;
  padding: 70px 0 50px 0;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 2.7em;
}

.sub-heading-font {
  text-align: center;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 1.2em;
}

textarea {
  font-family: "Trebuchet MS", sans-serif;
  border-radius: 30px;
  border-style: hidden;
}

.image-style {
  width: 100%;
  max-width: 740px;
  margin-top: 40px;
  border:2px solid #021a40;
}
